import PropTypes from 'prop-types';
import React from 'react';
import classNames from '../../util/classNames';

const PageH1 = ({ children, classes }) => (
  <h1
    className={classNames(
      'text-2xl font-semibold tracking-wide text-rose-400 lg:text-4xl',
      classes
    )}
  >
    {children}
  </h1>
);

PageH1.propTypes = {
  children: PropTypes.string.isRequired,
  classes: PropTypes.string,
};

PageH1.defaultProps = {
  classes: '',
};

export default PageH1;
