import PropTypes from 'prop-types';
import React from 'react';

const PageH2 = ({ children }) => (
  <h2 className="text-rose-300 text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium tracking-tight mt-12 mb-6">
    {children}
  </h2>
);

PageH2.propTypes = {
  children: PropTypes.string.isRequired,
};

export default PageH2;
