import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { PageH1 } from '../components/headers';
import Layout from '../components/layout';
import ReleasesDisplay from '../components/release-display/releasesDisplay';
import SEO from '../components/seo';
import { releaseType } from '../types';
import arrayHasValues from '../util/arrayHasValues';

export const query = graphql`
  {
    upcomingReleases: allStrapiReleases(
      filter: { isFuture: { eq: true } }
      sort: { fields: ReleaseDate, order: ASC }
      limit: 6
    ) {
      nodes {
        id
        Title
        ReleaseDate(formatString: "YYYY-MM-DD")
        Type
        artists {
          Name
        }
        Cover {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

const Releases = ({ data }) => {
  const { upcomingReleases } = data;

  return (
    <Layout>
      <SEO title="Releases" />
      <div className="px-8 mb-16">
        <PageH1 classes="mt-8 mb-12">DQBT Upcoming Releases</PageH1>
        {arrayHasValues(upcomingReleases.nodes) ? (
          <div className="">
            <ReleasesDisplay releases={upcomingReleases.nodes} artists noDate />
          </div>
        ) : (
          <div className="text-xl text-gray-300" style={{ minWidth: '60vw' }}>
            <p>No upcoming releases.</p>
          </div>
        )}
        <div className="mt-16 text-center">
          <Link
            to="/releases"
            className="m-auto p-4 text-2xl rounded-xl text-indigo-300 hover:text-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-300"
          >
            All Releases
          </Link>
        </div>
      </div>
    </Layout>
  );
};

Releases.propTypes = {
  data: PropTypes.shape({
    upcomingReleases: PropTypes.arrayOf(releaseType).isRequired,
  }).isRequired,
};

export default Releases;
